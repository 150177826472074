
export default {
  name: "PopupMsg",
  props: {
    message: {},
    position: { type: String, default: "bottom-left" },
    autohide: { default: 7000 },
    lastOnTop: { default: false },
    compactDuplicates: { default: true },
  },
  watch: {
    autohide(newVal, oldVal) {
      window.systemMsgTimeout = setTimeout(() => {
        this.close("auto");
      }, Number(newVal));
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.showHide();
  },
  methods: {
    showHide() {
      clearTimeout(window.systemMsgTimeout);
      this.show = true;
      if (!this.autohide || !Number(this.autohide)) return;

      window.systemMsgTimeout = setTimeout(() => {
        this.close("auto");
      }, Number(this.autohide));
    },
    close(state) {
      this.show = false;
      this.$emit("close", state);
      clearTimeout(window.systemMsgTimeout);
    },
  },
  watch: {
    msg(msg) {
      msg && msg.length && this.showHide();
    },
    autohide() {
      this.showHide();
    },
  },
  computed: {
    msg() {
      if (!this.message) return [];
      let messages = Array.isArray(this.message)
        ? this.message
        : [this.message];

      if (this.compactDuplicates) {
        const tempArray = messages.map((el1) => {
          let count = 0;
          const elString1 = JSON.stringify(el1);
          messages.forEach(
            (el2) => elString1 == JSON.stringify(el2) && count++
          );
          count < 2 && (count = null);
          return JSON.stringify({ ...el1, count });
        });
        messages = [...new Set(tempArray)].map((e) => JSON.parse(e));
      }

      return messages;
    },
    computedStyle() {
      let pos;
      const propPosition =
        this.msg[this.msg.length - 1].position || this.position;
      if (propPosition === "center") {
        pos = [
          { top: "50%" },
          { left: "50%" },
          { transform: "translate(calc(-50% - 1rem), -50%)" },
        ];
      } else {
        const tempPosition = propPosition.split("-");
        pos = [{ [tempPosition[0]]: "0" }, { [tempPosition[1]]: "0" }];
      }
      return [...pos];
    },
  },
};
