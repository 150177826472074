
import moment from "moment-timezone";
export default {
  methods: {
    isNight() {
      // Use the Intl.DateTimeFormat API to get the user's timezone
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Create a Moment.js object with the user's timezone
      const now = moment().tz(userTimezone);

      // Define the hours for night (e.g., 7 PM to 6 AM)
      const nightStartHour = 19; // 7 PM
      const nightEndHour = 6; // 6 AM

      // Get the current hour
      const currentHour = now.hour();

      // Check if it's currently night
      return currentHour >= nightStartHour || currentHour < nightEndHour;
    },
  },
  computed: {
    isDarkTheme() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    isDarkTheme(newVal) {
      sessionStorage.setItem("isDarkTheme", JSON.stringify(newVal));
    },
  },
  mounted() {
    if (sessionStorage.getItem("isDarkTheme")) {
      this.$vuetify.theme.dark = JSON.parse(
        sessionStorage.getItem("isDarkTheme")
      );
    } else {
      this.$vuetify.theme.dark = this.isNight();
    }
  },
};
