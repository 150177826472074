const state = () => ({
  uiState: Date.now(),
  nextRoute: "/",
  systemMsgs: [],
  menuSelected: null,
});

const getters = {};
const actions = {};

const mutations = {
  NEXT_ROUTE(state, payload = "/") {
    state.nextRoute = payload;
  },
  SET_MSGS(state, payload) {
    if (!payload) return (state.systemMsgs = []);
    Array.isArray(payload)
      ? (state.systemMsgs = state.systemMsgs.concat(payload))
      : state.systemMsgs.push(payload);
  },
  SELECTED_MENU(state, payload) {
    state.menuSelected = payload;
  },
  STATE_RESET(state) {
    state.uiState = Date.now();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
