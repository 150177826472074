
export default {
  props: {
    size: {
      type: String,
      default: "24px",
    },
    color: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "line-md:confirm-circle",
    },
    noCache: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentColor() {
      let color = "";
      // console.log("color", this.color);
      if (this.color) {
        color = this.color;
        if (/^( +\s?)?var/i.test(color)) {
          const computedStyle = getComputedStyle(document.documentElement);
          color = computedStyle.getPropertyValue(
            color.replace(/var( +\s?)?\(|\)/g, "").trim()
          );
        }
        color = encodeURIComponent(color);
        // console.log("from if",color);
      }
      // console.log("from else",color);
      return color;
    },
    iconStyle() {
      return {
        width: this.size,
        height: this.size,
        maxWidth: this.size,
        maxHeight: this.size,
      };
    },
  },
};
