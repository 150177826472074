import { render, staticRenderFns } from "./default.vue?vue&type=template&id=78437545&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/Users/ishani/Desktop/growmystock/Frontend/stock-screener/components/Logo.vue').default,Icon: require('/Users/ishani/Desktop/growmystock/Frontend/stock-screener/components/Icon.vue').default,ThemeBtn: require('/Users/ishani/Desktop/growmystock/Frontend/stock-screener/components/ThemeBtn.vue').default,PopupMsg: require('/Users/ishani/Desktop/growmystock/Frontend/stock-screener/components/PopupMsg.vue').default})
