
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "48px",
    },
  },
};
