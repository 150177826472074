export default {
  company: "GrowMyStock",
  logo: "https://www.growmystock.com/icon.png",
  socialImage: "https://www.growmystock.com/social.jpeg",
  url: "https://www.growmystock.com",
  domain: "growmystock.com",
  baseURL: `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001"
      : "https://grow-my-stock-backend.onrender.com"
  }`,
  githubRepo: "",
};
