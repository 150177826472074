
import GLOBAL from "../constants/global";

export default {
  name: "DefaultLayout",
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${GLOBAL.url}${this.$route.path}`,
        },
      ],
    };
  },
  data() {
    return {
      title: "Grow My Stock",
      miniVariant: true,
      drawer: false,
      items: [
        {
          icon: "mdi:chart-timeline-variant-shimmer",
          title: "Latest prediction",
          to: "/",
          ariaLabel: "Link to Home Page",
        },
        {
          icon: "mdi:archive-clock-outline",
          title: "Older prediction",
          to: "/older-stock-picks",
          ariaLabel: "Link to Older Predictions Page",
        },
        {
          icon: "mdi:chart-areaspline",
          title: "Chart",
          to: "/chart",
          ariaLabel: "Link to Chart Page",
        },
      ],
    };
  },
};
